<template>
    <div class="pay-success">
        <span class="icon-result success">
            <i class="icon-ok"></i>
        </span>
        <p class="result-text">{{$t('order.paymentSuccessful')}}</p>
        <div>
            <router-link :to="{name: 'account-name', params: {name: 'order'}}" class="button button-plain">{{$t('user.viewOrder')}}</router-link>
            <router-link :to="{name: 'account-name', params: {name: 'dashboard'}}" class="button button-plain" style="margin-left: 20px;">{{$t('user.dashboard')}}</router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'checkCode',
    data() {
        return {
            checkPass: false,
        }
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';
.pay-success {
    width: @container;
    margin: 50px auto;
    text-align: center;
    min-height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @size: 72px;
    .icon-result {
        display: block;
        width: @size;
        height: @size;
        margin: 0 auto 24px;
        border-radius: 50%;
        line-height: @size;
        color: #fff;
        font-size: 36px;
        &.success {
            background-color: @green-color;
        }
        &.fail {
            background-color: @red-color;
        }
    }
    .result-text {
        font-size: 24px;
        line-height: 1.8;
        text-align: center;
        margin-bottom: 20px;
    }
}
</style>